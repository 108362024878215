<template>
    <div class="page-header">
        <div class="d-none d-sm-block" style="position: absolute;right:20px;">
            <digital-clock></digital-clock>
        </div>
        <div class="text-center">
            <h3 class="text-white mt-4">{{preset.app?preset.app.name:'云课堂'}}</h3>
        </div>
    </div>
</template>

<script>
    import DigitalClock from "./digital-clock";
    import {mapState} from "vuex";

    export default {
        name: "page-header",
        components: {DigitalClock},
        data() {
            return {
            }
        },
        computed: {
            ...mapState(['user', 'process', 'preset']),

        }
    }
</script>

<style lang="scss">
    .page-header {
        height: 120px;
        padding: 10px 0;

        .logo {
            display: flex;

            img {
                max-height: 70px;
            }

            .title {
                padding-top: 10px;
                padding-left: 20px;
                font-size: 30px;
                font-weight: 400;
                color: #607d8b;
            }
        }

    }
</style>
