<template>
    <div class="clock">
        <div class="date">{{ date }} {{ time }} </div>
    </div>
</template>

<script>

    function zeroPadding(num, digit) {
        var zero = '';
        for(var i = 0; i < digit; i++) {
            zero += '0';
        }
        return (zero + num).slice(-digit);
    }
    export default {
        name: "digital-clock",
        data(){ return {
            date:'',
            time:''
        }},
        created() {
            const that = this;
            setInterval(function () {
                that.updateTime();
            },1000)
        },
        methods:{
            updateTime(){
                const cd = new Date();
                const week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
                this.time = zeroPadding(cd.getHours(), 2) + ':' + zeroPadding(cd.getMinutes(), 2) + ':' + zeroPadding(cd.getSeconds(), 2);
                this.date = zeroPadding(cd.getFullYear(), 4) + '-' + zeroPadding(cd.getMonth()+1, 2) + '-' + zeroPadding(cd.getDate(), 2) + ' ' + week[cd.getDay()];
            }
        }
    }
</script>

<style scoped lang="scss">
    .clock {
        height: 100px;
        color: #333333;
        .date {
            font-size: 16px;
            margin-right: 10px;
        }

    }


</style>
