
export default {
    mounted() {
        const vm=this;
        if (!this.oauth && !this.$organization.sso) this.admin_login=true;
        if (this.$organization.sso ) {  // 如果设置的是sso 那么就直接跳转连管理员输入都不显示
            if (!this.$route.query.ticket) { // read user info
               vm.jumpToSSO();
               return;
            }
        }
        if (this.$organization.oauth || this.$organization.sso) { // 这是显示管理员界面+单点登录界面
            if (this.$route.query.ticket) { // read user info
                this.$api('SSO' + '?ticket=' + this.$route.query.ticket, null).then(res => {
                    if (res.data.success) {
                        vm.setUserInfo(res.data.data);
                        vm.$router.push("/");
                    } else {
                        vm.$messageBox.error('错误的SSO返回信息,重新登录',null,()=>{
                            window.location.href = this.$organization.sso;
                        });
                    }
                });
            } else if (this.$route.query.code ){ // && this.$route.query.state && this.$route.query.state === 'zoom') {
                this.$api('SSO', {code: this.$route.query.code,state:this.$route.query.state},1).then(res => {
                    if (res.data.success) {
                        if (res.data.data.error) {
                            this.show_login_error = true;
                            this.error_message = res.data.data.message;
                        } else {
                            vm.setUserInfo(res.data.data);
                            vm.$router.replace("/");
                        }
                    } else {
                        this.$messageBox.error(res.data.text,null,()=>{
                            this.jumpToSignIn()
                        })
                    }
                }).catch(()=>{
                    this.ready = true;
                });
            } else if (this.$route.query.from == 'portal') {
                vm.jumpToOAuth2();

            } else {
                this.ready = true;
            }
        } else {
            this.ready = true;
        }
    },
    computed: {
        oauth(){
            return !!this.$organization.oauth;
        }
    },
    methods: {
        jumpToOAuth2() {
            const return_url = this.$organization.signin_url;
            window.location.href = this.$organization.oauth
                + encodeURIComponent(return_url);
        },
        jumpToSSO() {
            const return_url = this.$organization.signin_url;
            window.location.href = this.$organization.sso
                + encodeURIComponent(return_url);
        },
        jumpToSignIn(){
            this.$router.push('/sign-in');
            this.show_login_error = false;
            this.ready = true;
        }
    },
}
