import {mapMutations, mapState} from "vuex";
import OathMixin from "../../common/mixins/oauth-mixins";

export default {
    name: "sign-in",
    mixins: [OathMixin],
    data() {
        return {
            ready: false,
            admin_login: false,
            error_message: 'error message',
            show_login_error: false,
            background_images: [],
            login: {
                id: '',
                password: ''
            },
        }
    },
    created() {
        if (this.$organization.background_images) {
            this.background_images = this.$organization.background_images;
        }

    },
    computed: {
        ...mapState(['user', 'process', 'preset']),
        helper() {
            return this.$organization.oauth_helper;
        }
    },
    methods: {
        ...mapMutations('user', ['setUserInfo']),
        resetPassword() {
            const vm = this;
            if (vm.login.id) {
                vm.$affirm('我们将向您的邮箱发送一份包含有密码的邮件, 是否继续?').then(() => {
                    vm.$api('ResetPassword', vm.login, true).then(() => {
                        vm.$messageBox.success('密码已经发出，请检查您的邮箱。');
                    })
                })
            } else {
                vm.$notice.error('请重新输入账号/邮箱');
            }
        },
        submitForm() {
            const vm = this;
            if (!vm.login.id) {
                vm.$notice.error('请重新输入账号/邮箱');
            } else {
                vm.$api('SignIn', vm.login, true).then(response => {
                    vm.setUserInfo(response.data.data);
                    vm.$router.replace("/");
                });
            }
        },
    }
}
